import {SET_INVIEW, HOVER_OVER_NAVBAR, SUB_MENU_CONTROLLER, SET_BANNERS, SET_BURGER_CLICK, 
    SET_VACANCIES, SET_SINGLE_ITEM, SET_INPUT, SET_COMPONENT_ITEMS} from '../constants'

type IInViewProps = {
    type:string;
    payload: string;
}


export const setInView = (inView: IInViewProps) => {
    return {
        type : SET_INVIEW,
        payload : inView
    }
}

export const setNavBarBool = (bool:boolean) => {
    return {
        type: HOVER_OVER_NAVBAR,
        payload: bool
    }
}

export const setShowMenu = (bool:boolean) => {
    return {
        type:SUB_MENU_CONTROLLER,
        payload: bool
    }
}

export const setBannerItems = (bannerItems:any) => {
    return {
        type : SET_BANNERS,
        payload : bannerItems
    }
}

export const setBurgerClick = (click:boolean) => {
    return {
        type: SET_BURGER_CLICK,
        payload: click
    }
}

export const setVacanciesItems = (vacancyItems:any) => {
    return {
        type : SET_VACANCIES,
        payload : vacancyItems
    }
}

export const setItem = (item:any) => {
    return {
        type : SET_SINGLE_ITEM,
        payload : item
    }
}

export const setInput = (item:any) => {
    return {
        type: SET_INPUT,
        payload: item
    }
}

export const setComponentItems = (items:any) => {
    return {
        type: SET_COMPONENT_ITEMS,
        payload: items
    }
}