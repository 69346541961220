import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getAllBanners } from "../services/get";
import { setBannerItems } from "../store/actions/index";

export function UseBanners() {
  const dispatch = useDispatch();
  useEffect(() => {
    getAllBanners().then((result) => dispatch(setBannerItems(result)));
  }, []);
  return <></>;
}
