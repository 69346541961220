import { useSelector, useDispatch, RootStateOrAny } from 'react-redux'
import Logo from '../../logo';
import Nav from '../Nav'
import { setBurgerClick } from '../../../../store/actions'
import { useHistory } from "react-router-dom";
import { homeLocation } from '../../../../constants/routing'


export default function Burger() {
  const burger = useSelector((state:RootStateOrAny) => state.burger)
  const router = useHistory()
  const dispatch = useDispatch()

  const handleChecked = () => {
    dispatch(setBurgerClick(!burger))
  };

  const handleClick = () => {
    router.push(homeLocation)
  }

  return (
    <div className="menu-wrap">
      <div onClick={() => handleClick()} className="logo_burger">
        <Logo/>
      </div>
      <input onChange={handleChecked} type="checkbox" id="toggle" style={{ display: "none" }} />
      <label className={burger ? "toggle-btn btn-toggle-btn" : "toggle-btn"} htmlFor="toggle">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </label>
      <Nav footerCheck={true}  className={burger ? "active-nav" : undefined} />
    </div>
  );
}
