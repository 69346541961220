
import NavMenu from '../Nav'
import {Box, Container} from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import Logo from '../../logo';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { homeLocation } from '../../../../constants/routing';
import { setNavBarBool } from '../../../../store/actions'

const Bar: React.FC = () => {
    const router = useHistory()
    const dispatch = useDispatch();
    const [location] = useState(router.pathname)
    
    // 200 px bellow 0 on Y axis
    const [visibleInteger] = useState(150)

    //sets state
    const [state, setState] = useState({
        prevScrollpos: 0,
        visible: true
    })

    // handles scroll positions and returns true if scroll is above 200
    const handleScroll = () => {
        if (document.body.offsetHeight > 2000) {
            const currentScrollPos = window.pageYOffset;
            const visible = visibleInteger > window.pageYOffset;
            setState({ prevScrollpos: currentScrollPos, visible })
        }
    }

    const { prevScrollpos, visible } = state;

    // add listener on scroll and update if prevScroll Val has been changed. 
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll)
        };
    }, [prevScrollpos])

   

    return (

        <Box className={location === homeLocation ? "position-absolute __nav_bar_ruler" : "__nav_bar_ruler"} onMouseEnter={()=>dispatch(setNavBarBool(true))} onMouseLeave={()=>dispatch(setNavBarBool(false))}>
            <Box className={visible ? "__nav_bar" : "__nav_bar_fixed"}   >
                <Container className="__divider">
                    <Logo/>
                    <NavMenu/>
                </Container>
            </Box>
         </Box>
    )
}


export default Bar;
