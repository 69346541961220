import { useState, useEffect } from 'react';
import InViewTracker from 'src/components/trackers/InView';
import {ReactComponent as Hexagon } from '../../../assets/svg/hexagon.svg'

const EyeCatcher = () =>  {
    const [ticking, setTicking] = useState<boolean>(true),
        [count, setCount] = useState<number>(0),
        [hex_0, setHex_0] = useState<number>(0),
        [hex_1, setHex_1] = useState<number>(0),
        [hex_2, setHex_2] = useState<number>(0),
        [hex_3, setHex_3] = useState<number>(0),
        [hex_4, setHex_4] = useState<number>(0)


    useEffect(() => {
        const timer = setInterval(() => ticking && setCount(count+.8), 1e3)
 
        setHex_0(Math.floor(Math.random() * 9) + 1 )
        setHex_1(Math.floor(Math.random() * 9) + 1 )
        setHex_2(Math.floor(Math.random() * 10) + 1 )
        setHex_3(Math.floor(Math.random() * 9) + 1 )
        setHex_4(Math.floor(Math.random() * 9) + 1 )
        
      
        return () => clearInterval(timer)
      
       },[count, ticking])


    return (
   
            <div className="eyecatcher">
                <Hexagon style={{fill: "#ffffff",  transition:"all 1s ease-in-out", left: 140, bottom:110, opacity:'.'+hex_0}}/>
                <Hexagon style={{fill: "#ffffff",  transition:"all 1s ease-in-out", left: 160, bottom:250, opacity:'.'+hex_1}}/>
                <Hexagon style={{fill: "#ffffff",  transition:"all 1s ease-in-out", left: 10, bottom:55, opacity:'.'+hex_2}}/>
                <Hexagon style={{fill: "#ffffff",  transition:"all 1s ease-in-out", left: 30, bottom:200, opacity:'.'+hex_3}}/>
                <Hexagon style={{fill: "#ffffff",  transition:"all 1s ease-in-out", left: -100, bottom:150, opacity:'.'+hex_4}}/>
                <InViewTracker string="about"/>
            </div>
    
    )
}


export default EyeCatcher;