import { getComponent } from './get';

export const getAsyncComponents = async () => {
    const about = await getComponent(65);
    const bediende = await getComponent(62);
    const contact = await getComponent(63);
    const jobs = await getComponent(64);
    const contact_info = await getComponent(63);
    const socials = await getComponent(80);
    const privacyPolicy = await getComponent(61);
    const cookies = await getComponent(60);
    const components = {
      about,
      bediende,
      contact,
      jobs,
      contact_info,
      socials,
      privacyPolicy,
      cookies
    }

    return components;

  }