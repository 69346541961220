import "normalize.css"
import "animate.css"
import "bootstrap-utilities"
import "./global.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import fire, { firebaseConfig } from "./services/firebase"
import Router from "./services/routes"
import { UseComponents } from "./api/components"
import { UseVacancies } from "./api/vacancies"
import { RootStateOrAny, useSelector } from "react-redux"
import Loader from "./components/elements/loader"
import { useEffect, useState } from "react"
import { UseBanners } from "./api/banners"
import Cookie from "./components/elements/cookie"
function App() {
  const vacancyItems = useSelector(
    (state: RootStateOrAny) => state?.vacancyItems
  )
  const components = useSelector((state: RootStateOrAny) => state.components)
  const bannerItems = useSelector((state: RootStateOrAny) => state.bannerItems)
  const [loaded, setLoaded] = useState(false)
  try {
    fire.initializeApp(firebaseConfig)
  } catch (err) {
    if (!/already exists/.test(err.message)) {
      console.error("Firebase initialization error", err.stack)
    }
  }

  useEffect(() => {
    if (vacancyItems && components && bannerItems) {
      setLoaded(true)
    }
  }, [vacancyItems, components, bannerItems])

  return (
    <>
      <UseComponents />
      <UseVacancies />
      <UseBanners />
      {loaded ? (
        <>
          <Router />
          <Cookie />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default App
