import Header from '../../components/sections/header'
import Footer from '../../components/sections/footer'
import ScrollAnimation from "react-animate-on-scroll";


import Markdown from '../../components/elements/markdown'

import React from 'react';
import { Container } from '@material-ui/core';
import { RootStateOrAny, useSelector } from 'react-redux';
import Head from 'src/components/elements/head';


const Cookies = (props:any) => {
    const cookies = useSelector((state:RootStateOrAny) => state.components.cookies)


        return (
       
       <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={.1}>    
          <Head title={cookies.title} website="www.1force.be" description={cookies.wys_text}/>
          <Header front={true}/>

          <Container style={{marginTop:"10em", paddingBottom:"5em"}}>
                  <h3  className="my-3">{cookies.title}</h3>
                  <Markdown input={cookies.wys_text}/>
          </Container>
          <Footer/>
        </ScrollAnimation>
     
        ) 
    
   
}

export default Cookies;