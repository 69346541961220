import { Box, Container, Grid } from '@material-ui/core';
import Nav from '../../elements/navigation/Nav'
import Secondary from '../../elements/navigation/Secondary'
import Contact from '../../elements/navigation/Contact'

import {ReactComponent as FooterSVG} from '../../../assets/svg/footer.svg'

import {isMobile} from 'react-device-detect'

import { useSelector, RootStateOrAny } from 'react-redux';
const Footer =() => {
    const about = useSelector((state:RootStateOrAny) => state.components.about)

  
    return (
        <footer>
            <Container>
                <Box className="d-flex flex-column h-100">
                   <Box className="text-center my-5">
                        <h3 className="mb-3">1FORCE</h3>
                        <p>{about.description}</p>
                    </Box>
              
                    <Grid container spacing={isMobile ? 0 : 8} className="submenu-parent">
                        <Grid item md={4} sm={12} xs={12} className="submenu">
                            <h4>Hoofd menu</h4>
                            <Nav footerCheck={true}/>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} className="submenu">
                            <h4>Secundair menu</h4>
                           <Secondary/>
                        </Grid> 
                        <Grid item md={4} sm={12} xs={12} className="submenu">
                            <h4>Contact</h4>
                            <Contact/>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box className="footer-bottom">
                   <FooterSVG/>
                   <Box className="inner">
                       <a href="https://www.methods.digital">DESIGN & DEVELOPMENT BY METHODS</a>
                   </Box>
            </Box>
         
        </footer>
    )
}

export default Footer;