import Header from "../../components/sections/header";
import About from "../../components/sections/about";
import Jobs from "../../components/sections/jobs";
import Contact from "../../components/sections/contact";
import Footer from "../../components/sections/footer";
import { RootStateOrAny, useSelector } from "react-redux";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Head from "src/components/elements/head";
import InViewTracker from "src/components/trackers/InView";

const Index: React.FC = () => {
  const about = useSelector((state: RootStateOrAny) => state.components.about);
  return (
    <>
      <Head
        title={"Homepage"}
        website="www.1force.be - United IT Brains"
        description={about.about_description}
      />
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={0.2}>
        <Header />
        <InViewTracker string="header" />
        <About />
        <Jobs />
        <Contact />
        <Footer />
      </ScrollAnimation>
    </>
  );
};

export default Index;

function dispatch(arg0: any, arg1: void) {
  throw new Error("Function not implemented.");
}
