import Header from '../../../../components/sections/header'
import Footer from '../../../../components/sections/footer'
import ScrollAnimation from "react-animate-on-scroll";
import { useEffect, useState } from 'react'
import { Container, Grid } from '@material-ui/core'
import InViewTracker from '../../../../components/trackers/InView'
import { useLocation } from 'react-router-dom';
import Markdown from '../../../../components/elements/markdown'
import Form from '../../../../components/elements/contactform'
import { UseVacancy } from 'src/api/vacancy';
import { RootStateOrAny, useSelector } from 'react-redux';
import Loader from 'src/components/elements/loader';
import Head from 'src/components/elements/head';

export default function Freelancer(){
     const location = useLocation();
     const id = location.pathname.split('/')
     const job = id[3]
     const data = useSelector((state:RootStateOrAny) => state.item)
     const [loaded, setLoaded] = useState<boolean>(false)

      useEffect(() => {
       if(data){
         if(job === data.id) {
            setLoaded(true)
         }
        
       }
     
      }, [data, job])

      if (loaded) {
      return (
        <>
       
        <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={.3}>    
          <Header front={true}/>
          <InViewTracker string="jobs"/>
          <Head title={data.item.title} website="www.1force.be" description={data.item.vacancy_description}/>
           <Container id="detail_page" className="d-flex justify-content-around flex-column my-5">
                  <h3 className="mt-3 mb-5 ">{data.item.title}</h3>
                  <Grid container>
                        <Grid item md={3} sm={12} xs={12}>
                          <span>Referentie: {data.item.ref}</span>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <span>Start: {data.item.starts}</span>
                          </Grid>
                          <Grid item md={3} sm={12} xs={12}>
                          <span>Duur: {data.item.amount}</span>
                          </Grid>
                          <Grid item md={3} sm={12} xs={12}>
                          <span>Regio: {data.item.region}</span>
                          </Grid>
                          <Grid item md={12}  sm={12} xs={12} className="my-5">
  
                          <Markdown input={data.item.vacancy_description}/>
                          </Grid>
                  </Grid>
                  <h3 className="text-center">Solliciteer nu!</h3>
          <Form/>
          </Container>
          <Footer/>
          </ScrollAnimation>
          </>
      )
      }
     
      else{
        return  <><Loader/><UseVacancy id={job}/></>
      }
  }
  