import Header from "../../../components/sections/header";
import Footer from "../../../components/sections/footer";
import { Container } from "@material-ui/core";
import InViewTracker from "../../../components/trackers/InView";
import ExtendedTable from "../../../components/elements/table/extendedTable";
import { RootStateOrAny, useSelector } from "react-redux";

import ScrollAnimation from "react-animate-on-scroll";
import Head from "src/components/elements/head";

export default function Freelancer() {
  const jobs = useSelector((state: RootStateOrAny) => state.components.jobs);

  return (
    <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={0.1}>
      <Head
        title={jobs.title_2}
        website="www.1force.be"
        description={jobs.jobs_description_freelancer}
      />
      <Header front={true} />
      <InViewTracker string="jobs" />
      <Container className="d-flex justify-content-center flex-column text-center my-5">
        <h3 className="my-3">Openstaande Vacatures</h3>
        <ExtendedTable />
      </Container>
      <Footer />
    </ScrollAnimation>
  );
}
