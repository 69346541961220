import InViewTracker from '../../trackers/InView';
import { Box, Container, Grid } from '@material-ui/core'
import ScrollAnimation from "react-animate-on-scroll";
import {ReactComponent as Handshake} from '../../../assets/svg/handshake.svg'
import { RootStateOrAny, useSelector } from 'react-redux';
import Markdown from 'src/components/elements/markdown';


const About: React.FC = () => {
    const about = useSelector((state:RootStateOrAny) => state.components.about)
    return (
        <section id="about" >
            <Box className="background-el">
                <Handshake/>
            </Box>
            <Container className="my-5">
                <ScrollAnimation animateOnce={true} animateIn="fadeInDown" duration={.6}>
                    <h3 className="text-center mb-5">{about.title}</h3>
                </ScrollAnimation>
                <InViewTracker string="about"/>
                <Grid container spacing={4}>
             
                    <Grid item md={6} sm={12} className="d-flex justify-content-center">
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={.8}>
                            <img alt={about.title} width="600px" src={about.headshot_about}></img>
                        </ScrollAnimation>
                    </Grid>
                    
                    {<Grid item md={6} className="about_text d-flex justify-content-center align-items-center">
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={.9}>
                        <Markdown input={about.about_description}/>
                        
                        </ScrollAnimation>
                    </Grid>}
                </Grid>
            </Container>
        </section>
    )
}

export default About;