import { useEffect } from 'react'
import { useDispatch } from 'react-redux';

import { getVacancy } from '../services/get';
import { setItem } from '../store/actions/index';

export function UseVacancy({id}) {
    const dispatch = useDispatch();
    useEffect(() => {
        getVacancy(id).then((item)=>{
            dispatch(setItem({id, item}))
        })
      },[id])
    return (<></>)
}
