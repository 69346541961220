

const Loader = () => {
    return (
        <div className="loading_screen">
        <h2 className="text-center">Loading 1force.be</h2>
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader;