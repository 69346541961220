import Hero from '../../../components/elements/hero'
import InViewTracker from '../../../components/trackers/InView'
import React, { ReactNode } from 'react'
import Navigation from '../../elements/navigation'
import Topbar from '../../elements/topbar'

const Header = (props:{front?:boolean,children?:ReactNode}) => {
    const {front} = props;
    return (
        <header>
          
            <Topbar/>
            <Navigation/>
            {!front && <><InViewTracker string="header"/><Hero/></>}
      
        </header>
    )
}

export default Header;