import { useEffect } from "react";

import {
  aboutLocation,
  contactLocation,
  homeLocation,
  jobsLocation,
} from "../../../../constants/routing";
import ListItem from "../ListItem";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { setShowMenu } from "../../../../store/actions";
// Type Definition
interface INavProps {
  className?: string;
  footerCheck?: boolean;
  burger?: boolean;
}

const Nav = (props: INavProps) => {
  const { className } = props;
  const dispatch = useDispatch();

  //Sets Global State To True If Mouse Hovers Over NavBar
  const hoverOverNavBar = useSelector(
    (state: RootStateOrAny) => state.hoverOverNavBar
  );
  useEffect(() => {
    if (hoverOverNavBar === false) {
      dispatch(setShowMenu(false));
    }
  }, [hoverOverNavBar]);

  return (
    <nav className={className}>
      <ul>
        <ListItem href={homeLocation} text="Home" />
        <ListItem href={aboutLocation} text="Over Ons" />
        {props.footerCheck ? (
          <ListItem href={jobsLocation} text="Jobs" />
        ) : (
          <ListItem href={jobsLocation} text="Jobs" sub={true} />
        )}
        <ListItem href={contactLocation} text="Contact" />
      </ul>
    </nav>
  );
};

export default Nav;
