import {ReactComponent as LogoSvg} from '../../../assets/svg/logo.svg'


const Logo = () =>{
    return (
        <a href="/" className="p">
             <LogoSvg/>
        </a>
    )
}


export default Logo;