import {Helmet} from 'react-helmet';


const Head = (props) => {
    const {title, description} = props;

    

    return (  <Helmet>
        <title>{title} | 1FORCE - UNITED IT BRAINS</title>c
        <meta charSet="UTF-8"/>
        <meta name="description" content={description} />
        <meta name="keywords" content={title && title.split(" ") +  "," + description.split(" ")} />
        <meta name="author" content={title} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        </Helmet> )
}


export default Head