import axios from "axios";

export const getComponent = async (id: number) => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/components/${id}`
    )
    .then((res) => {
      return res.data.acf;
    });
};

export const getAllComponents = async () => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/components`
    )
    .then((res) => {
      return res.data;
    });
};

export const getAllBanners = async () => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/banners`
    )
    .then((res) => {
      return res.data;
    });
};

export const getVacancy = async (id: number) => {
  return axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/vacancies/${id}`
    )
    .then((res) => {
      return res.data.acf;
    });
};

export const getAllVacancies = async (offset:number) => {
  return await axios
    .get(
      `${
        process.env.REACT_APP_MAIN_API +
        process.env.REACT_APP_MAIN_JSON +
        process.env.REACT_APP_MAIN_EXTENDED_FIELD
      }/vacancies?per_page=100?offset=${offset}`
    ).then((res)=>{
      return res.data
    })
};
