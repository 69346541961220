import Header from "../../components/sections/header";
import Footer from "../../components/sections/footer";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Typography } from "@material-ui/core" ;
import Head from "src/components/elements/head";
const Error = () => {
  return (
    <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={0.1}>
      <Head title="404 Page" website="www.1force.be" description={""} />
      <Header front={true} />

      <Container
        style={{ marginTop: "10em", paddingBottom: "5em", textAlign: "center" }}
      >
        <h1 className="my-3">ERROR:404</h1>
        <Typography>Pagina Bestaat niet!</Typography>
      </Container>
      <Footer />
    </ScrollAnimation>
    
  )
}

export default Error
