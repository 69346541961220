import {IProps} from './typing'

const Button = (props: IProps) => {

    const {text, link, className, children = "Click"} = props;
    const value = text || children;
    const clickHandler = () => {
        window.location.href = link
    }
    

    return (
        <button onClick={clickHandler} className={!className? "btn" : className}>
            {value ? value : "Verder gaan"}
        </button>
    )
}

export default Button;