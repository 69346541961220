import Header from '../../components/sections/header'
import Footer from '../../components/sections/footer'
import ContactComponent from '../../components/sections/contact'

import ScrollAnimation from "react-animate-on-scroll";
import { RootStateOrAny, useSelector } from 'react-redux';
import Head from 'src/components/elements/head';

  
  export default function Contact(props:any) {

    const contact = useSelector((state:RootStateOrAny) => state.components.contact)
    
    return (
    
       <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={.1}>    
             <Head title={contact.title} description={contact.contact_description}/>
            <Header front={true}/>
            <ContactComponent />
            <Footer/>
        </ScrollAnimation>

     
    )
}
