import { homeLocation } from "../../../../constants/routing";

import { useEffect, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Animated } from "react-animated-css";
import { setBurgerClick } from "../../../../store/actions";

const ListItem = (props: { href: string; text: string; sub?: boolean }) => {
  const { href, text, sub } = props;
  const dispatch = useDispatch();
  const linkRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  const inView = useSelector((state: RootStateOrAny) => state.inView);
  const navBar = useSelector((state: RootStateOrAny) => state.hoverOverNavBar);
  const burger = useSelector((state: RootStateOrAny) => state.burger);

  useEffect(() => {
    if (inView === href.substring(1)) {
      linkRef.current && linkRef.current.classList.add("active");
    }
    if (inView === "header" && href === homeLocation) {
      linkRef.current && linkRef.current.classList.add("active");
    }

    return () => {
      linkRef.current && linkRef.current.classList.remove("active");
    };
  }, [inView, props]);

  const showSubMenu = () => {
    if (sub) {
      setShowMenu(true);
    }
  };

  useEffect(() => {
    if (navBar === false) {
      setShowMenu(false);
    }
  }, [navBar]);

  return (
    <li ref={linkRef} onMouseEnter={() => showSubMenu()}>
      <a onClick={() => dispatch(setBurgerClick(!burger))} href={href}>
        {text}
      </a>
      {sub ? <KeyboardArrowDownIcon /> : undefined}
      {sub && (
        <Animated
          animationIn="fadeInDown"
          animationOut="fadeOutDown"
          animateOnMount={false}
          isVisible={showMenu}
          animationInDuration={100}
          animationOutDuration={100}
        >
          <ul className="__sub_menu">
            <li>
              <a href="/jobs/freelancer">Vacatures</a>
            </li>
            <li>
              <a href="/jobs/consultant">Bediende</a>
            </li>
          </ul>
        </Animated>
      )}
    </li>
  );
};

export default ListItem;
