import {Container, Box} from '@material-ui/core'
import LinkedIn from '../icons/linkedIn'
import Facebook from '../icons/facebook'
import Phone from "../icons/phone";
import Email from "../icons/email";

import Instagram from "@material-ui/icons/Instagram";
import Whatsapp from "../icons/whatsapp";
import { RootStateOrAny, useSelector } from "react-redux";



const Topbar = () => {
    const contact_info = useSelector((state:RootStateOrAny) => state.components.contact_info)
    const socials = useSelector((state:RootStateOrAny) => state.components.socials)




    return ( <section className="top_bar">
          <Container>
       
                <Box className="inner d-flex justify-content-between align-items-center text-white">
                    <ul className="left">
                        {contact_info.gsm && <li >
                            <a href={"tel:" + contact_info.gsm}>
                                <span className="mr-2"><Phone/></span>
                                <span>{contact_info.gsm}</span>
                            </a>
                        </li>}
                        {contact_info.email && <li>
                            <a href={"mailto:" + contact_info.email} >
                                  <span className="mr-2"> <Email/></span>
                                  <span>{contact_info.email}</span>
                            </a>
                        </li>}
                    </ul>
            
                    <ul className="right">
                        {socials.facebook && 
                           <li>
                                <a href={socials.facebook}>
                                    <Facebook/>
                                </a>
                            </li>
                        }
                        {socials.whatsapp && 
                           <li>
                                <a href={socials.whatsapp}>
                                    <Whatsapp/>
                                </a>
                            </li>
                        }
                        {socials.instagram && 
                           <li>
                                <a href={socials.instagram}>
                                    <Instagram/>
                                </a>
                            </li>
                        }
                        {socials.linkedin && 
                           <li>
                                <a href={socials.linkedin}>
                                    <LinkedIn/>
                                </a>
                            </li>
                        }
                    </ul>
                </Box>
            </Container>
             
        </section>
    )
}


export default Topbar;