import { BrowserRouter, Route, Switch } from "react-router-dom"
import {
  homeLocation,
  aboutLocation,
  jobsLocation,
  freelancerLocation,
  consultantLocation,
  contactLocation,
  privacyLocation,
  cookieLocation,
  siteMapLocation,
} from "../constants/routing"
import Home from "../pages/home"
import About from "../pages/about"
import Jobs from "../pages/jobs"
import Freelancer from "../pages/jobs/freelancer"
import Consultant from "../pages/jobs/consultant"
import Contact from "../pages/contact"
import Privacy from "../pages/privacy-policy"
import Cookies from "../pages/cookies"
import { RootStateOrAny, useSelector } from "react-redux"
import VacancyDetail from "../pages/jobs/freelancer/detail"
import SiteMap from "src/pages/site-map"
import Error from "src/pages/error"
export default function Routes() {
  const vacancyItems = useSelector((state: RootStateOrAny) => state?.vacancyItems)
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={homeLocation} render={() => <Home />} />
        <Route exact path={siteMapLocation} render={() => <SiteMap />} />
        <Route exact path={aboutLocation} render={() => <About />} />
        <Route exact path={jobsLocation} render={() => <Jobs />} />
        <Route exact path={freelancerLocation} render={() => <Freelancer />} />
        <Route exact path={consultantLocation} render={() => <Consultant />} />
        <Route exact path={contactLocation} render={() => <Contact />} />
        <Route exact path={privacyLocation} render={() => <Privacy />} />
        <Route exact path={cookieLocation} render={() => <Cookies />} />

        <Route
          path="/admin"
          component={() => {
            window.location.href = "https:///api.1force.be/beheer"
            return null
          }}
        />
        {vacancyItems.map((vacancy, i) => {
          return <Route exact key={i} path={"/jobs/freelancer/:" + vacancy.id + "/"} component={VacancyDetail} />
        })}
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
    
  )
}
