import { privacyLocation } from "../../../constants/routing";

import {
  Grid,
  FormControl,
  TextField,
  Typography,
  Link,
  Button,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import InputComponent from "../input";
import Alert from "@material-ui/lab/Alert";
import { Animated } from "react-animated-css";
import CheckIcon from "@material-ui/icons/Check";
import { setInput } from "../../../store/actions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { postContactForm } from "../../../services/post";
import fire from "../../../services/firebase";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReCAPTCHA from "react-google-recaptcha";

export default function Form() {
  const [disabled, setDisabled] = useState(true);
  const [correctFileType, setCorrectFileType] = useState<boolean>(false);
  const [uploadFileName, setUploadFileName] = useState<string>();
  const [correctSize, setCorrectSize] = useState<boolean>();
  const [error, setError] = useState<string>();
  const [showError, setShowError] = useState<boolean>(false);
  const [botFix, setBotFix] = useState<boolean>(false);
  const formRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const name = useSelector((state: RootStateOrAny) => state.name);
  const email = useSelector((state: RootStateOrAny) => state.email);
  const phone = useSelector((state: RootStateOrAny) => state.phone);
  const message = useSelector((state: RootStateOrAny) => state.message);
  const [file, setFile] = useState<any>();
  const [googleFileConfirm, setGoogleFileConfirm] = useState<boolean>(false);
  const [googleFormConfirm, setGoogleFormConfirm] = useState<boolean>(false);
  const [getFullDownloadPath, setFullDownloadPath] = useState<string>(null);
  const [includedCV, setIncludedCV] = useState<boolean>(false);
  const googleSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_PROD;
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleBotValue = (e) => {
    if (e.target.value.length >= 1) {
      setBotFix(true);
    }
  };

  const handleToDefault = () => {
    setTimeout(() => {
      setError("");
      setShowError(false);
    }, 50000);
  };
  const fireSwalGoogle = () => {
    const GoogleRec = withReactContent(Swal);
    GoogleRec.fire({
      title: "Even bevestingen...",
      html: (
        <div className="d-flext justify-content-center  w-100">
          <ReCAPTCHA
            sitekey={googleSiteKey}
            onChange={() => setGoogleFileConfirm(true)}
          />
        </div>
      ),
      didOpen: () => {
        if (googleFileConfirm) GoogleRec.clickConfirm();
      },
    });
  };

  const handleFile = (e) => {
    let size = e.target.files[0].size;
    let fileName = e.target.files[0].name;
    if (googleFileConfirm) {
      const extension = fileName.split(".");
      const absoluteEx = extension[extension.length - 1];
      if (absoluteEx === "pdf" || absoluteEx === "docx") {
        if (size > 1000) {
          if (size < 5000000) {
            setUploadFileName(fileName);
            const storageRef = fire.storage().ref();
            const child = storageRef.child(
              "files/" + uuidv4() + "-" + e.target.files[0].name
            );
            child
              .put(e.target.files[0])
              .then(() =>
                Swal.fire("Gelukt!", "Het bestand werd geupload!", "success")
              )
              .then(async () => {
                setCorrectSize(true);
                setDisabled(false);
                const downloadUrl = await child.getDownloadURL();
                setFullDownloadPath(downloadUrl);
                setShowError(false);
                setIncludedCV(true);
              });
          } else {
            setError("Bestand mag niet groter zijn dan 5mb");
          }
        } else {
          setCorrectSize(false);
          setShowError(true);
          setError("Bestand mag niet kleiner zijn dan 1kb");
        }
        setCorrectFileType(true);
      } else {
        setCorrectFileType(false);
        setShowError(true);
        setError("Gelieve alleen een PDF of DOC bestand te selecteren.");
        setDisabled(true);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!botFix) {
      if (googleFormConfirm) {
        let body = new FormData();
        body.set("name-client", name);
        body.set("email", email);
        body.set("phone", phone);
        body.set("message", message);
        body.set("fileUrl", getFullDownloadPath);

        await postContactForm(body, includedCV);

        formRef.current.reset();
        setCorrectSize(false);
        setUploadFileName(null);
        setDisabled(true);
        setFile(null);
      }
    }
  };
  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(
      setInput({
        [e.currentTarget.name]: e.currentTarget.value,
      })
    );
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      ref={formRef}
      onClick={handleToDefault}
      className="d-flex flex-wrap"
    >
      <Animated
        className="w-100 my-2 mb-3"
        animationIn="fadeInDown"
        animationOut="fadeOutDown"
        animateOnMount={false}
        isVisible={showError}
        animationInDuration={100}
        animationOutDuration={100}
      >
        <Alert severity="error">{error}</Alert>
      </Animated>
      <Grid container spacing={4}>
        <InputComponent
          type="name"
          label="Naam"
          id="name"
          helper="Vul hier uw naam in."
          duration={0.6}
          aria="first-name"
        />
        <InputComponent
          type="tel"
          label="Telefoonnummer"
          id="phone"
          helper="Vul hier uw telefoonnummer in."
          duration={0.58}
          aria="phone"
        />
        <InputComponent
          type="email"
          label="Uw e-mailadres"
          id="email"
          helper="Vul hier uw email in."
          duration={0.56}
          aria="e-mail"
        />

        <Grid item md={6} sm={12} xs={12}>
          <ScrollAnimation
            className="w-100"
            animateOnce={true}
            animateIn="fadeInDown"
            duration={0.54}
          >
            <FormControl className="w-100 mt-2">
              <Button
                className="border-bottom shadow-sm"
                onClick={!googleFileConfirm ? fireSwalGoogle : handleClick}
              >
                {correctFileType && uploadFileName ? (
                  <>
                    {uploadFileName}
                    <CheckIcon />
                  </>
                ) : (
                  "CV uploaden ... "
                )}
              </Button>
              <input
                onChange={handleFile}
                id="upload"
                type="file"
                name="file"
                aria-describedby="file-upload"
                ref={hiddenFileInput}
                style={{ display: "none" }}
              />
              <FormHelperText id="upload">
                Formaat in .pdf of .docx . maximaal 5 mb
              </FormHelperText>
            </FormControl>
          </ScrollAnimation>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <ScrollAnimation
            className="w-100"
            animateOnce={true}
            animateIn="fadeInDown"
            duration={0.52}
          >
            <TextField
              onChange={handleChange}
              required={true}
              name="message"
              id="outlined-multiline-static"
              label="Uw Bericht"
              multiline
              rows={4}
              className="shadow-sm w-100 "
            />
          </ScrollAnimation>
        </Grid>
        <p className="hidden">
          <label id="contact-form-bot-label">
            <span>Don't fill this out if you're human:</span>
            <input
              onChange={handleBotValue}
              name="bot-field"
              aria-labelledby="contact-form-bot-label"
            />
          </label>
        </p>
        <Grid item md={12} sm={12} xs={12}>
          <ScrollAnimation
            className="w-100"
            animateOnce={true}
            animateIn="fadeInDown"
            duration={0.5}
          >
            <Typography
              className="d-flex justify-content-end"
              style={{ color: "#ec1b31" }}
            >
              <small>* Veld verplicht in te vullen.</small>
            </Typography>
            <Typography>
              Wanneer u ons contactformulier verzendt verwerken wij de door u
              ingevulde gegevens. U dient hiermee akkoord te gaan:
            </Typography>
            <Link style={{ color: "#EC1B31" }} href={privacyLocation}>
              Privacy Beleid
            </Link>
          </ScrollAnimation>
        </Grid>
        <Grid item md={12} className="d-flex align-items-center">
          <input
            required={true}
            name="checkbox"
            type="checkbox"
            id="checkbox"
          />
          <label className="ml-2" htmlFor="checkbox">
            Ik ga akkoord
          </label>
        </Grid>
        <Grid item md={12}>
          <Box className="d-flext justify-content-center  w-100">
            <ReCAPTCHA
              sitekey={googleSiteKey}
              onChange={() => setGoogleFormConfirm(true)}
            />
          </Box>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <button className="btn">Indienen</button>
        </Grid>
      </Grid>
    </form>
  );
}
