import axios from "axios"
import Swal from "sweetalert2"

export const postContactForm = async (body, cv) => {


  const linkedContactUri = process.env.REACT_APP_MAIN_CONTACT_PROD
  const linkedContactUriCv = process.env.REACT_APP_MAIN_CONTACT_PROD_CV


  let link = process.env.REACT_APP_MAIN_API +  process.env.REACT_APP_MAIN_JSON + linkedContactUri
  if (cv) {
    link = process.env.REACT_APP_MAIN_API +  process.env.REACT_APP_MAIN_JSON + linkedContactUriCv
  }
  await axios({
    method: "post",
    url:  link,
    data: body,
    headers: { "content-type": "application/json" }
  })
    .then(() => {
      Swal.fire("Gelukt!", "Het formulier is verzonden!", "success")
    })
    .catch((error) => {
      Swal.fire(
        "Mislukt!",
        `Het formulier werd niet verzonden... ${error}`,
        "error"
      )
    })
}
