import React from 'react';
import Bar from './Bar'
import Burger from './Burger';


const Navigation: React.FC = () => {
    return (
        <nav>
            <Bar/>
            <Burger/>
        </nav>
    )
}

export default Navigation;