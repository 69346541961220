import { RootStateOrAny, useSelector } from "react-redux";
import Slider from "react-slick";
import Slide from './slide'

export default function HeroSlider() {
  const bannerItems = useSelector((state:RootStateOrAny) => state.bannerItems)

  // create settings for slider component
  const settings = {
    dots: true,
    arrows:true,
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover:true,
  };



  return (
    <div className="top_mast_slider">
          <Slider {...settings}>
            {bannerItems&& bannerItems.map((item, i) => {
                const {title, description, tekst_op_knop, link_van_knop, link_van_vacature} = item.acf;
                const picture = item.acf.foto_.sizes
                if (link_van_vacature) {
                  const vacancyLink = `/jobs/freelancer/${link_van_vacature}`
                  return <Slide key={i} title={title} subtitle={description} buttontext={tekst_op_knop} buttonhref={vacancyLink} picture={picture}/>
                }
                else{
                  return <Slide key={i} title={title} subtitle={description} buttontext={tekst_op_knop} buttonhref={link_van_knop} picture={picture}/>
                }
            })}
          
          </Slider>
    </div>
  );
}
