import Button from '../../button'
import ScrollAnimation from "react-animate-on-scroll";
import { IProps } from './typing'

export default function Slide(props:IProps) {
  const {title, subtitle, buttontext, buttonhref, picture} = props;
  const pictureUrl = picture["2048x2048"];
  return (
        <section className="slide">
            <ScrollAnimation offset={0} animateOnce={false} animateIn="fadeInUp" duration={.4}>
              <h1>{title}</h1>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={false} animateIn="fadeInUp" duration={.6}>
              <h3 className="text-center" >{subtitle}</h3>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={false} animateIn="fadeInUp" duration={.8}>
              <Button  className="btn" link={buttonhref}>{buttontext}</Button>
            </ScrollAnimation>
            <div className="__hero_image">
              <img alt={"hero_images"} src={pictureUrl}></img>
            </div>
        
        </section>
  );
}
