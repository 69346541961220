
import FacebookIcon from '@material-ui/icons/Facebook';

const Facebook: React.FC = () => {
    return (
        <FacebookIcon style={{ color: "white"}} />
    )
}



export default Facebook;