
import Phone  from '../../icons/phone'
import Email  from '../../icons/email'
import Linkedin from '../../icons/linkedIn';
import { RootStateOrAny, useSelector } from 'react-redux';

const ContactNav=() => {
    const contact = useSelector((state:RootStateOrAny) => state.components.contact)
    const socials = useSelector((state:RootStateOrAny) => state.components.socials)
    let gsmNummer;
    if (contact) {
        if(contact.gsm) {
           gsmNummer = contact.gsm.replaceAll(" ", "")
        }
    }
    return (
        <ul className="d-flex flex-row">
            <li>
                <a href={`tel:${gsmNummer}`} className="d-flex align-content-center mr-2">
                    <Phone/>
                </a>
            </li>
            <li>
                <a href={`mailto:${contact.email}`} className="d-flex align-content-center mx-2">
                    <Email/>
                </a>
            </li>
            <li>
                <a href={socials.linkedin} className="d-flex align-content-center mx-2">
                    <Linkedin/>
                </a>
            </li>
        </ul>
    )
}

export default ContactNav;