
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const Linkedin: React.FC = () => {
    return (
        <LinkedInIcon style={{ color: "white"}} />
    )
}



export default Linkedin;