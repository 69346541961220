module.exports = {
    SET_INVIEW: "SET_INVIEW",
    HOVER_OVER_NAVBAR : "HOVER_OVER_NAVBAR",
    SUB_MENU_CONTROLLER : "SUB_MENU_CONTROLLER", 
    SET_BANNERS : "SET_BANNERS",
    SET_BURGER_CLICK : "SET_BURGER_CLICK",
    SET_VACANCIES: "SET_VACANCIES",
    SET_SINGLE_ITEM : "SET_SINGLE_ITEM",
    SET_INPUT : "SET_INPUT",
    SET_COMPONENT_ITEMS : "SET_COMPONENT_ITEMS"
}