import Button from '../../../components/elements/button';
import { Card, CardContent,   Grid } from '@material-ui/core';
import ScrollAnimation from "react-animate-on-scroll";


type IProps = {
    title: string;
    description: string;
    link:string;
    buttontext: string;
}


const CardItem = (props: IProps) => {
    const {title, description, link, buttontext} = props;

    return (
        <Grid item md={4} sm={12}>
            <Card  className="py-5 mt-5 d-flex align-items-center flex-column" style={{borderRadius: 0, minHeight:"25vh"}}>
                <ScrollAnimation animateOnce={true} animateIn="fadeInDown" duration={.8}> 
                    <h2>{title}</h2>
                </ScrollAnimation>
                <CardContent>
                    <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={.9}> 
                        <p className="text-center px-4">{description}</p>
                    </ScrollAnimation>
                </CardContent>
                <ScrollAnimation animateOnce={true} animateIn="fadeInUp" duration={1}> 
                    <Button link={link}>{buttontext}</Button>
                </ScrollAnimation>
            </Card>
        </Grid>
    )
}


export default CardItem