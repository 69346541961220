import Header from '../../components/sections/header'
import Footer from '../../components/sections/footer'
import AboutComponent from '../../components/sections/about'
import ScrollAnimation from "react-animate-on-scroll";
import { RootStateOrAny, useSelector } from 'react-redux';

import Head from 'src/components/elements/head';

  
  export default function About() {
    const about = useSelector((state:RootStateOrAny) => state.components.about)
    return (
        
            

        <ScrollAnimation animateOnce={true} animateIn="fadeIn" duration={.1}>   

           <Head title={about.title} description={about.about_description}/>
            <Header front={true}/>
            <AboutComponent/>
            <Footer/>
          </ScrollAnimation>
   
       
    )
}
