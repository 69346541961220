import Header from '../../components/sections/header'
import Footer from '../../components/sections/footer'
import JobsComponent from '../../components/sections/jobs'
import Head from 'src/components/elements/head'
import { RootStateOrAny, useSelector } from 'react-redux'

  export default function Jobs(props:any) {
    const jobs = useSelector((state:RootStateOrAny) => state.components.jobs)
    return (<>
            <Head title={jobs.title} website="www.1force.be" description={jobs.jobs_description}/>
            <Header front={true}/>
            <JobsComponent/>
            <Footer/>
            </>
    )
}
