import Header from '../../components/sections/header'
import Footer from '../../components/sections/footer'



import Markdown from '../../components/elements/markdown'

import { Container } from '@material-ui/core';
import { RootStateOrAny, useSelector } from 'react-redux';
import Head from 'src/components/elements/head';




const PrivacyPolicy = () => {
    const privacyPolicy = useSelector((state:RootStateOrAny) => state.components.privacyPolicy)

    
        return (
       <>
        <Head title={privacyPolicy.title} website="www.1force.be" description={privacyPolicy.wys_text}/>
        <Header front={true}/>
        <Container style={{marginTop:"10em", paddingBottom:"5em"}} >
                <h3 className="my-3">{privacyPolicy.title}</h3>
                <Markdown input={privacyPolicy.wys_text}/>
        </Container>
        <Footer/>
       </>
        ) 
    
   
}

export default PrivacyPolicy;