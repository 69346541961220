
import {privacyLocation, cookieLocation, siteMapLocation} from '../../../../constants/routing'

const SecondaryMenu: React.FC = () => {
    return (
        <ul className="secondary_menu">
            <li>
                <a href={privacyLocation}>Privacy Policy</a>
            </li>
            <li>
                <a href={cookieLocation}>Cookies</a>
            </li>
            <li>
                <a href={siteMapLocation}>Site-Map</a>
            </li>
        </ul>
    )
}

export default SecondaryMenu;