import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { applyMiddleware, compose, createStore } from "redux";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import { loadState, saveState } from "./store/localstorage";
import mainReducer from "./store/reducers";
import { Provider } from "react-redux";
const config = { blacklist: [] };
const middlewares = [createStateSyncMiddleware(config)];

const persistedState = loadState();

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares)

);
const store = createStore(mainReducer, persistedState, enhancer);

store.subscribe(() => {
  saveState(store.getState());
});

initMessageListener(store);
ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
  



