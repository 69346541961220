import InViewTracker from '../../trackers/InView'
import { Container, } from '@material-ui/core'
import Map from '../../elements/map'
import Form from '../../../components/elements/contactform';
import { RootStateOrAny, useSelector } from 'react-redux';
import Markdown from 'src/components/elements/markdown';

export default function Contact(props:{standalone?:boolean,front?:boolean}) {
    const {standalone, front } = props;
    const contact = useSelector((state:RootStateOrAny) => state.components.contact)
    return (
        <section id="contact">
           <Container className="my-5">
            {!standalone &&<> <h3 className="text-center">{contact.title}</h3>
            <Markdown input={contact.contact_description}/>
          </>}
            {!front&& <InViewTracker string="contact"/>}
                <Form/>
            </Container>
           <Map/>
        </section >
    )
}
