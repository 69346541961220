import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";

import { getAllVacancies } from "../services/get";
import { setVacanciesItems } from "../store/actions/index";



export function UseVacancies() {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState<number>(0);



  useEffect(() => {
    
    getAllVacancies(amount).then((res) => {
      res.map(
        (item: {
          id: number;
          acf?: {
            vacancy_description?: string;
            vacancy_short?: string;
            slug: any;
          };
        }) => {
          const single = sanitizeHtml(item.acf.vacancy_description);
          if (item.acf.vacancy_description.length > 50) {
            item.acf.vacancy_short =
              sanitizeHtml(single, { allowedTags: [] }).substr(0, 50) + "...";
          } else {
            item.acf.vacancy_short = sanitizeHtml(single, { allowedTags: [] });
          }
          return (item.acf.slug = "freelancer/" + item.id);
        }
      );
      res.sort((a,b) => {
        return - (a.id - b.id)
      })
      dispatch(setVacanciesItems(res));
      // setTimeout(() => {
      //   setAmount(vacancies?.length);
      // }, 1000);
    });

    // getAllVacancies(amount).then((result) => {
    //   result.map(
    //     (item: {
    //       id: number;
    //       acf?: {
    //         vacancy_description?: string;
    //         vacancy_short?: string;
    //         slug: any;
    //       };
    //     }) => {
    //       setAmount(result.length);
    //       const single = sanitizeHtml(item.acf.vacancy_description);
    //       if (item.acf.vacancy_description.length > 50) {
    //         item.acf.vacancy_short =
    //           sanitizeHtml(single, { allowedTags: [] }).substr(0, 50) + "...";
    //       } else {
    //         item.acf.vacancy_short = sanitizeHtml(single, { allowedTags: [] });
    //       }
    //       return (item.acf.slug = "freelancer/" + item.id);
    //     }
    //   )
    //   dispatch(setVacanciesItems(result));
    // });
  }, []);

 

  // useEffect(() => {

  // }, [input])

  return <></>;
}
