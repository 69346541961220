
import WhatsappIcon from '@material-ui/icons/WhatsApp';

const Whatsapp: React.FC = () => {
    return (
        <WhatsappIcon style={{ color: "white"}} />
    )
}



export default Whatsapp;