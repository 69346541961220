import { Box, Button } from "@material-ui/core";
import Cookies from 'js-cookie'
import { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import 'animate.css'
export default function Cookie() {
    const [cookie, setCookie] = useState(false)
    useEffect(() => {
        const gdpr = Cookies.get('gdpr');
        if (!gdpr) {
           setCookie(true)
        }    
    }, [cookie])
    
    const handleClick = () => {
        Cookies.set('gdpr', 'accepted-GDPR-Conditions', { expires: 255 })
        setCookie(false)
    }
    
    const handleCancel = () => {
        setCookie(false)
    }

    return (
        
      <>
        {cookie && <Animated animationIn="fadeIn" animationOut="fadeOut" animateOnMount={false} isVisible={cookie} style={cookie &&{height:"100%", position:"static"}}>
                    <Box className="cookies-consent">
                        <Box className="cookies-inner" component="div">
                            <span>Deze website maakt gebruikt van cookies om uw gebruikers ervaring te verbeteren.</span>
                            <span className="py-2"><a href="/cookies">Meer info?</a></span>
                            <Box className="d-flex">
                                <Button onClick={handleClick}>Accepteer</Button> 
                                <Button onClick={handleCancel}>Weigeren</Button> 
                            </Box>
                        </Box>
                    </Box>
        </Animated>}
     </>
       
    )
}
