
import { 
    SET_INVIEW,
    HOVER_OVER_NAVBAR,
    SET_BANNERS,
    SET_BURGER_CLICK,
    SET_VACANCIES,
    SET_SINGLE_ITEM,
    SET_INPUT,
    SET_COMPONENT_ITEMS
} from '../constants';

import { loadState } from "../localstorage";
import { withReduxStateSync } from "redux-state-sync";

const mainReducer = (state = loadState(), action) => {
    switch (action.type) {
        case SET_INVIEW: {
            return { ...state, inView :  action.payload }
        }
        case HOVER_OVER_NAVBAR: {
            return {...state, hoverOverNavBar : action.payload}
        }
        case SET_BANNERS: {
            return {...state, bannerItems : action.payload}
        }
        case SET_BURGER_CLICK: {
            return {...state, burger : action.payload}
        }
        case SET_VACANCIES: {
            return {...state, vacancyItems : action.payload}
        }
        case SET_SINGLE_ITEM: {
            return {...state, item : action.payload}
        }
        case SET_INPUT: {
            return { ...state, ...action.payload}
        }
        case SET_COMPONENT_ITEMS:{
            return {...state, components : action.payload}
        }
        default :
            return state;
    }
}


export default withReduxStateSync(mainReducer);