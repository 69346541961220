import { setInput } from '../../../store/actions';
import { Grid, FormControl, InputLabel, FormHelperText, Input } from '@material-ui/core'
import ScrollAnimation from "react-animate-on-scroll";
import { useDispatch,  } from 'react-redux';
import {IProps} from './typings'

const InputComponent = (props: IProps) => {
    const {label, id, helper, duration, type} = props;
    const dispatch = useDispatch()
    const handleChange = (e) => {

        dispatch(
          setInput({
              [e.currentTarget.name]: e.currentTarget.value,
          })
        );
        if (type) {
            
        }

      };

   



    return (
        <Grid item md={6} sm={12} xs={12}>
        <ScrollAnimation className="w-100" animateOnce={true} animateIn="fadeInDown" duration={duration}>
            <FormControl  className="w-100">
                <InputLabel className="pl-2" htmlFor={id}>{label}*</InputLabel>
                <Input type={type}  required={true} onChange={handleChange}  name={id} className="shadow-sm" id={id} aria-describedby={id} />
                <FormHelperText id={id}>{helper}</FormHelperText>
            </FormControl>
        </ScrollAnimation>
    </Grid>
    )
}

export default InputComponent;
