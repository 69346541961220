
import PhoneIcon from '@material-ui/icons/Phone';

const Phone: React.FC = () => {
    return (
        <PhoneIcon style={{ color: "white"}} />
    )
}


export default Phone;