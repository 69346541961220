import InViewTracker from '../../../components/trackers/InView';
import { Box, Container, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {ReactComponent as Triangles} from '../../../assets/svg/traingles.svg'
import ScrollAnimation from "react-animate-on-scroll";
import CardItem from './card';
import {freelancerLocation, consultantLocation} from '../../../constants/routing'
import { RootStateOrAny, useSelector } from 'react-redux';
import Markdown from 'src/components/elements/markdown';


const Jobs: React.FC = () => {
    const jobs = useSelector((state:RootStateOrAny) => state.components.jobs)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
      if (jobs) {
          setLoading(true)
      }
      
  }, [jobs])
    return (
        <section id="jobs">
           {loading && <Container className="my-5">
                <ScrollAnimation animateOnce={true} animateIn="fadeInDown" duration={.6}>
                    <h3 className="text-center mb-5">{jobs.title}</h3>
                </ScrollAnimation>
                <InViewTracker string="jobs"/>
                <ScrollAnimation animateOnce={true} animateIn="slideInUp" duration={.7}>  
                <Markdown input={jobs.jobs_description}/>
            
                </ScrollAnimation>
                <Grid container spacing={5} className="mt-5 d-flex justify-content-around">
                    <Box className="d-flex background-jobs-front">
                        <Triangles/>
                   
                    </Box>
                    <CardItem title={jobs.title_2} description={jobs.jobs_description_freelancer} link={freelancerLocation} buttontext="Jobs"/>
                    <CardItem title={jobs.title_3} description={jobs.jobs_description_consultant} link={consultantLocation} buttontext="Info"/>
                </Grid>
            </Container>}
        </section>
    )
}

export default Jobs;