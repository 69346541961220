import Header from '../../../components/sections/header'
import Footer from '../../../components/sections/footer'
import { Container } from '@material-ui/core'
import Markdown from '../../../components/elements/markdown'
import Form from '../../../components/elements/contactform'
import { RootStateOrAny, useSelector } from 'react-redux'
import Head from 'src/components/elements/head'


function Consultant() {
    const consultant = useSelector((state:RootStateOrAny) => state.components.bediende)

    return (
        
        <>    
            <Head title={consultant.title} website="www.1force.be" description={consultant.consulant_description}/>
            <Header front={true}/>
            <Container className="my-5">
            <h3 className="markdown mb-2">{consultant.title}</h3>
            <Markdown input={consultant.consulant_description}/>
            <Form/>
            </Container>

            <Footer/>
      
       </>
    )
}

export default Consultant;