import Slider from '../slider'
import { Box } from '@material-ui/core'
import EyeCatcher from '../eyecatcher';
const Hero = () => {
    return (
        <section id="hero">
            <Box className="hero__slider_container">
                <Slider />
                <EyeCatcher />
             
            </Box>
        </section>
    )
}

export default Hero
